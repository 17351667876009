.shop-background {
    background-color: #f2f2f2;
    height: 100%;
}

.shop {
    height: 90vh;
}

.shop-message {
    padding-top: 70px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
}

.shop-filter {
    font-size: 12px;
    font-weight: 300;
    padding-top: 100px;
    padding-left: 70px;
    display: flex;
}

.shop-filter img {
    height: 10px;
    padding-top: 3px;
}

.shop-button {
    font-weight: 300;
    position: fixed;
    z-index: 1;
    margin-right: 130px;
    padding-top: 20px;
    right: 0;
    border: none;
    background-color: #f2f2f2;
}

.shop-button span {
    font-size: 12px;
}

.shop-footer {
    display: inline-grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100vw;
    height: 100%;
}

.shop-footer a {
    text-decoration: none;
    color: #000;
}

.shop-footer a:hover {
    color: #000;
    text-decoration: line-through;
}

.shop-footer p {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
}

.shop-footer p:not(#instagram) {
    justify-self: right;
    padding-right: 30px;
}

.shop-footer-link {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
}

.shop-footer-link p {
    padding-left: 0 !important;
}

.shop-footer-link p a {
    font-size: 14px;
    font-weight: 300;
    color: black;
    text-decoration: none;
}

.shop-footer-link a:hover {
    text-decoration: line-through !important;
}

.shop-footer-social-media {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.shop-footer-social-media p {
    padding-left: 0 !important;
    text-decoration: none;
}

.shop-footer-social-media a {
    text-decoration: none;
    color: black;
    margin-left: 15px;
}

.shop-footer-social-media a:hover {
    color: black;
    text-decoration: line-through;
}