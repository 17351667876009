.home {
    background-color: #f2f2f2;
    scroll-behavior: smooth;
}

.home-buibuibui-background {
    scroll-snap-align: start;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.scroll-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.scroll-button a {
    text-decoration: none;
    background-color: transparent;
    color: white;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: 300;
    transition: all .2s ease-in-out;
}

.scroll-button a:hover {
    color: white;
    transform: scale(1.1);
}

.scroll-button a:hover span {
    text-decoration: line-through;
}

.home-buibuibui {
    position: absolute;
    left: 0;
    right: 0;
    top: 35%;
    margin: 0 auto;
    text-align: center;
}

.home-buibuibui h1 {
    display: inline;
    color: #f2f2f2;
    font-size: 14vw;
    font-weight: bold;
    letter-spacing: 5px;
    font-weight: 300;
}

.home-buibuibui p {
    color: #f2f2f2;
    padding: 7px 16px;
    text-decoration: none;
    font-weight: 300;
    padding-left: 300px;
}

.home-buibuibui h1:hover+p span {
    text-decoration: line-through;
}

.home-buibuibui p span:hover {
    text-decoration: line-through;
}

.home-shop-background {
    scroll-snap-align: start;
    width: 100vw;
    height: 50vh;
    display: flex;
    position: relative;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.home-shop {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    left: 0;
    right: 0;
    top: 30%;
    margin: 0 auto;
    text-align: center;
}

.home-shop h1 {
    display: flex;
    margin: 0 auto;
    color: #f2f2f2;
    font-size: 6vw;
    letter-spacing: 5px;
    font-weight: 300;
}

.home-shop h1:hover+p span {
    text-decoration: line-through;
}

.home-shop p span:hover {
    text-decoration: line-through;
}

.home-shop p {
    color: #f2f2f2;
    padding-left: 70px;
    text-decoration: none;
}

.home-allCollections-background {
    scroll-snap-align: start;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
}

.home-allCollections-background-image1,
.home-allCollections-background-image2 {
    background-size: cover;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.home-allCollections {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    left: 0;
    right: 0;
    top: 40%;
    margin: 0 auto;
    text-align: center;
}

.home-allCollections h1:hover+p span {
    text-decoration: line-through;
}

.home-allCollections h1:hover+p {
    transform: scale(1.1);
}

.home-allCollections p:hover {
    transform: scale(1.1);
}

.home-allCollections p span:hover {
    text-decoration: line-through;
}

.home-allCollections h1 {
    width: 100vw;
    color: #f2f2f2;
    font-size: 12vw;
    letter-spacing: 10px;
    font-weight: 300;
    white-space: nowrap;
}

.home-allCollections p {
    color: #f2f2f2;
    text-decoration: none;
}

.home-about-background {
    scroll-snap-align: start;
    width: 100vw;
    height: 50vh;
    display: flex;
    position: relative;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.home-about {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    left: 0;
    right: 0;
    top: 30%;
    margin: 0 auto;
    text-align: center;
}

.home-about h1:hover+p span {
    text-decoration: line-through;
}

.home-about h1:hover+p {
    transform: scale(1.1);
}

.home-about p:hover {
    transform: scale(1.1);
}

.home-about p span:hover {
    text-decoration: line-through;
}

.home-about p {
    color: #f2f2f2;
    padding-left: 100px;
    text-decoration: none;
}

.home-about h1 {
    display: flex;
    margin: 0 auto;
    color: #f2f2f2;
    font-size: 6vw;
    font-weight: bold;
    letter-spacing: 5px;
    font-weight: 300;
}

.home-footer-background {
    background-color: #322828;
    scroll-snap-align: start;
    width: 100vw;
    height: 50vh;
    display: flex;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
}

.home-footer {
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    margin: 0 auto;
    text-align: center;
}

.home-footer h1:hover+p span {
    text-decoration: line-through;
}

.home-footer h1:hover+p {
    transform: scale(1.1);
}

.home-footer p:hover {
    transform: scale(1.1);
}

.home-footer p span:hover {
    text-decoration: line-through;
}

.home-footer p {
    color: #f2f2f2;
    padding-left: 150px;
    font-weight: 300;
    text-decoration: none;
}

.home-footer p {
    color: #f2f2f2;
    text-decoration: none;
}

.home-footer h1 {
    display: inline;
    white-space: nowrap;
    color: #f2f2f2;
    font-size: 6vw;
    letter-spacing: 10px;
    font-weight: 300;
}

.scroll-container {
    position: relative;
    width: 100vw;
    height: 45px;
    overflow-x: hidden;
    margin-top: 50px;
    padding: 10px;
    padding-bottom: 8px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.scroll-track {
    width: 100vw;
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: scroll-container 20s linear infinite;
    color: #f2f2f2;
}

@keyframes scroll-container {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

.home-footer a:hover {
    text-decoration: none;
}

.line {
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid grey;
}

.home-footer-social-media {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.home-footer-social-media p {
    padding-left: 0 !important;
}

.home-footer-social-media a {
    text-decoration: none;
    color: white;
    margin-right: 15px;
}

.home-footer-social-media a:hover {
    color: white;
    text-decoration: line-through;
}

.home-footer-link {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
}

.home-footer-link p {
    padding-left: 0 !important;
}

.home-footer-link p a {
    font-size: 14px;
    color: white;
    text-decoration: none;
}

.home-footer-link a:hover {
    text-decoration: line-through !important;
}