.info {
    background-color: #e0e1e0;
}

.info-header {
    background-color: #e0e1e0;
    text-align: center;
    padding-top: 100px;
}

.info-header h1 {
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 0;
}

.info-content p {
    font-size: 9px;
    font-weight: 300;
}

.info-content {
    padding: 0 150px;
    padding-top: 20px;
}