.about-heading-background {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.about-heading-background p {
    position: absolute;
    width: 100px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    font-weight: 300;
    padding-bottom: 30px;
}

.about-heading {
    position: absolute;
    left: 0;
    right: 10%;
    top: 25%;
    margin: 0 auto;
    text-align: center;
}

.about-heading h1 {
    font-size: 10vw;
    font-weight: 300;
    text-align: center;
}

.about-heading h2 {
    font-size: 5vw;
    font-weight: 300;
    text-align: center;
}

.about-heading h3 {
    padding-left: 200px;
    font-size: 2vw;
    font-weight: 300;
    text-align: center;
}

.about-page1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100vw;
    height: 100vh;
    color: #000;
    padding-top: 10px;
    text-align: center;
}

.about-page1 h1 span {
    font-weight: 400;
}

.about-page1 h1 {
    font-weight: 300;
    font-size: 25px;
}

.about-page1 p {
    font-weight: 300;
}

.about-page1 p span {
    font-weight: bold;
}

.about-page1-text1 {
    margin-top: 50px;
}

.about-page1-text1 h2 {
    margin-top: 50px;
    font-size: 25px;
    font-weight: 400;

}

.about-page1-text1 p {
    font-size: 14px;
}

.about-page2 {
    width: 100vw;
    height: 100%;
    min-height: 50vh;
    background-color: #2a282a;
    color: #dfe5e6;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.about-page2-heading {
    margin-top: 10px;
}

.about-page2-heading p {
    margin-bottom: 50px;
    font-size: 14px;
    font-weight: 200;
}

.about-page2-heading h2 {
    font-weight: 300;
    font-size: 25px;
}

.about-page2-heading h2 span,
.about-page2-heading h3 span {
    font-weight: 400;
}

.about-page2-heading h3 {
    font-size: 25px;
    font-weight: 300;
}

.about-page2-text {
    margin-top: 50px;
}

.about-page2-text p {
    font-weight: 300;
}

.about-page3-background {
    height: 50vh;
    width: 100vw;
    background-color: #dfe5e6;
}

.about-page3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 50vh;
    padding-top: 30px;
}

.about-page3 p {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
}

.about-page3 p span {
    font-weight: 400;
}

.about-page4-background {
    height: 50vh;
    width: 100vw;
    background-color: #a8c1ba;
}

.about-page4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 50vh;
    padding-top: 10px;
}

.about-page4 p {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
}

.about-page4 h4 {
    font-size: 25px;
    font-weight: 300;
    text-align: center;
}

.about-page4 h4 span {
    font-weight: 400;
    letter-spacing: 3px;
}

.about-footer-background {
    background-color: #322828;
    scroll-snap-align: start;
    width: 100vw;
    height: 50vh;
    display: flex;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
}

.about-footer {
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    margin: 0 auto;
    text-align: center;
}

.about-footer a:hover {
    text-decoration: none;
}

.about-footer h1:hover+p span {
    text-decoration: line-through;
}

.about-footer p span:hover {
    text-decoration: line-through;
}

.about-footer p {
    color: #f2f2f2;
    text-decoration: none;
}

.about-footer h1 {
    white-space: nowrap;
    color: #f2f2f2;
    font-size: 25px;
    letter-spacing: 5px;
    font-weight: 400;
}

.scroll-container {
    position: relative;
    width: 100vw;
    height: 45px;
    overflow-x: hidden;
    margin-top: 50px;
    padding: 10px;
    padding-bottom: 8px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.scroll-track {
    width: 100vw;
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: scroll-container 20s linear infinite;
    color: #f2f2f2;
}

@keyframes scroll-container {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

.about-footer #instagram {
    text-decoration: underline;
    padding-top: 40px;
    font-size: 14px;
}

.about-footer #instagram:hover {
    text-decoration: line-through;
}

.about-footer-social-media {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.about-footer-social-media p {
    padding-left: 0 !important;
}

.about-footer-social-media a {
    text-decoration: none;
    color: white;
    margin-right: 15px;
}

.about-footer-social-media a:hover {
    color: white;
    text-decoration: line-through;
}

.about-footer-link {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
}

.about-footer-link p {
    padding-left: 0 !important;
}

.about-footer-link p a {
    font-size: 14px;
    color: white;
    text-decoration: none;
}

.about-footer-link a:hover {
    text-decoration: line-through !important;
}