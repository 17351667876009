.collections-background {
    background-color: #e0e1e0;
    height: 100%;
    width: 100vw;
}

.collections-header {
    display: grid;
    width: 100vw;
    height: 35vh;
    margin: 0 auto;
    align-items: center;
}

.collections-header h1,
.collections-header p {
    grid-column: 1;
    grid-row: 2;
    font-weight: 300;
    color: #000;
    text-align: center;
}

.collections-header h1 {
    font-size: 5vw;
}

.collections-header p {
    font-size: 1vw;
    padding-left: 50px;
}

.collections-header-line {
    width: 90vw;
    height: 3px;
    background-color: black;
    margin: 0 auto;
}

.collections-header-line2 {
    width: 90vw;
    height: 1px;
    background-color: black;
    margin: 0 auto;
}

.collections {
    font-family: "Satoshi";
    width: 100%;
    height: 30vh;
    right: 0;
    left: 0;
    margin: 0;
}

.collections:hover {
    background-image: url("./bg_nonconformity.png");
    background-size: cover;
}

.collections h1 {
    width: 100vw;
    font-size: 7vw;
    font-weight: 300;
    color: #000;
    text-align: center;
    padding-top: 50px;
}

.collections h1 span {
    color: #999;
    font-size: medium;
    font-weight: normal;
    line-height: 30px;
}

.collections:hover h1,
.collections:hover h1 span {
    color: white;
}

.collections a {
    text-decoration: none;
}

.collections-body {
    height: 100%;
}

.collections-footer {
    display: inline-grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100vw;
    margin-top: 175px;
}

.collections-footer a {
    text-decoration: none;
    color: #000;
}

.collections-footer a:hover {
    color: #000;
    text-decoration: line-through;
}

.collections-footer p {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
}

.collections-footer p:not(#instagram) {
    justify-self: right;
    padding-right: 30px;
}

.collections-footer-link {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
}

.collections-footer-link p {
    padding-left: 0 !important;
}

.collections-footer-link p a {
    font-size: 14px;
    font-weight: 300;
    color: black;
    text-decoration: none;
}

.collections-footer-link a:hover {
    text-decoration: line-through !important;
}

.collections-footer-social-media {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.collections-footer-social-media p {
    padding-left: 0 !important;
    text-decoration: none;
}

.collections-footer-social-media a {
    text-decoration: none;
    color: black;
    margin-left: 15px;
}

.collections-footer-social-media a:hover {
    color: black;
    text-decoration: line-through;
}