body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-snap-type: y mandatory;
    height: 100%;
    background-color: #e0e1e0;
}

#root {
    font-family: "Migha";
    overflow: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    cursor: default;
}

::selection {
    color: none;
    background: none;
}