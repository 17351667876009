.nonconformity-background {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('./nonconformity.png');
}

.nonconformity-heading {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    ;
    text-align: center;
}

.nonconformity-heading h1 {
    font-family: "Satoshi";
    color: #f2f2f2;
    font-size: 6vw;
    font-weight: bold;
    letter-spacing: 5px;
    line-height: 75%;
}

.nonconformity-heading h2 {
    color: #f2f2f2;
    font-size: 2vw;
    font-weight: 400;
    text-decoration: none;
    line-height: 75%;
    padding-right: 50px;
}

.nonconformity-heading h3 {
    color: #f2f2f2;
    font-size: 2vw;
    font-weight: 300;
    text-decoration: none;
    line-height: 250%;
    padding-left: 300px;
}