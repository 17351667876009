.logo {
    position: fixed;
    z-index: 1;
    margin-top: 20px;
    margin-left: 30px;
    left: 0;
}

.logo a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 300;
    width: fit-content;
    letter-spacing: 2px;
}

.logo a:hover {
    text-decoration: line-through;
}