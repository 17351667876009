.Sidenav {
    position: fixed;
    z-index: 1;
    margin-top: 20px;
    margin-right: 30px;
    right: 0;
}

.Sidenav a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    width: fit-content;
    transition: transform 0.3s ease-in-out;
}

.Sidenav a:hover {
    transform: scale(1.3);
    color: #cddc20 !important;
}